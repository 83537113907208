export const uploadCandidateData = (url, body) => fetch(
  url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(body)
  }
);

export const sendFileApi = (url, data) => fetch(
  url, {
    method: 'POST',
    body: data
  }
);

export const deleteResumeApi = url => fetch(
  url, {
    method: 'DELETE'
  }
);

export const getVacanciesApi = url => fetch(url);
