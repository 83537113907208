export const InitialValues = {
  lastName: null,
  firstName: null,
  middleName: null,
  city: null,
  phoneNumber: null,
  email: null,
  university: null,
  graduationYear: null,
  graduation: null,
  learningProgram: null,
  date: null,
  resumeId: null,
  coverLetter: null
};
