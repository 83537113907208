import { encodeFileName } from "../utils/encodeFileName";
import {
  getVacanciesApi,
  uploadCandidateData,
  sendFileApi,
  deleteResumeApi,
} from "../api/api";

export const sendCandidateData = async (data, meta) => {
  const url = '/questionnaire/candidates';

  const {
    setSubmited,
    setSubmitting,
    setIsSubmitError
  } = meta;

  try {
    const response = await uploadCandidateData(url, data);

    const responseBody = await response.json();

    setSubmited(true);
  } catch (error) {
    console.error('[Error in sendFormData]', error);
    setIsSubmitError(true);
  } finally {
    setSubmitting(false);
  }
};

export const sendFile = async (file, meta) => {
  const {
    setResumeId,
    setResumeUploading,
    setIsUploadError
  } = meta;

  const url = '/questionnaire/resumes';

  const encodedFile = encodeFileName(file);

  try {
    const formData = new FormData();
    formData.append('file', encodedFile);

    const response = await sendFileApi(url, formData);
    const responseBody = await response.json();

    setResumeId(responseBody[0].id);
  } catch (error) {
    setIsUploadError(true);
    console.error('[Error in sendFile]', error);
  } finally {
    setResumeUploading(false);
  }
};

export const deleteResume = async resumeId => {
  const url = `/questionnaire/resumes/${resumeId}`;

  try {
    const response = await deleteResumeApi(url);
    if (!response.ok) {
      throw response;
    }
  } catch (error) {
    console.error('[Error deleteFileOnServer]', error);
  }
};

export const getVacancies = async () => {
  const url = 'questionnaire/vacancies';
  let vacancies = null;

  try {
    const response = await getVacanciesApi(url);

    if (!response.ok) {
      throw response;
    } else {
      vacancies = await response.json();
    }
  } catch (error) {
    console.error('[Error getVacancies]:', error);
  }

  return vacancies;
};
