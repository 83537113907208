export function encodeFileName(file) {
  const encodedFile = new File(
    [file.slice(0, file.size, file.type)],
    encodeURIComponent(file.name),
    {
      lastModified: file.lastModified,
      type: file.type
    }
  );

  return encodedFile;
}
