// import { useState, useEffect } from 'react';

import { Field } from 'react-final-form';

// import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';

import InputForm from './Forms/InputForm';
import SelectForm from './Forms/SelectForm';

const Education = props => {
  return (
    <div className='section__wrapper'>
      <h2 className='section__wrapper__title'>
        {`Образование`}
      </h2>

      <div className='section__wrapper__line'>
        <InputForm
          name='university'
          key='university'
          type='university'
          label='ВУЗ'
          required
          fullWidth
          variant='outlined'
        />         
        <InputForm
          name='graduationYear'
          key='graduationYear'
          type='graduationYear'
          label='Год выпуска'
          required
          fullWidth
          variant='outlined'
        />
      </div>

      <div className='section__wrapper__line'>
        <Field
          name='graduation'
        >
          {props => (
            <SelectForm
              required
              name={props.input.name}
              value={props.input.value}
              values={[
                'Бакалавриат',
                'Магистратура',
                'Специалитет',
                'Аспирантура'
              ]}
              label='Степень'
              labelId='select-label'
            />
          )}
        </Field>
        <InputForm
          name='learningProgram'
          key='learningProgram'
          type='learningProgram'
          label='Специальность'
          required
          fullWidth
          variant='outlined'
        />
      </div>
    </div>
  );
};

export default Education;
