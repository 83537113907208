import InputForm from "./Forms/InputForm";

const ContactInformation = props => {
  return (
    <div className='section__wrapper'>
      <h2 className='section__wrapper__title'>
        {`Контактная информация`}
      </h2>

      <div className='section__wrapper__line'>
        <InputForm
          name='lastName'
          key='lastName'
          label='Фамилия'
          required
          fullWidth
          variant='outlined'
        />
        <InputForm
          name='firstName'
          key='firstName'
          label='Имя'
          required
          fullWidth
          variant='outlined'
        />
      </div>

      <div className='section__wrapper__line'>
        <InputForm
          name='middleName'
          key='middleName'
          label='Отчество'
          required
          fullWidth
          variant='outlined'
        />
        <InputForm
          name='city'
          key='city'
          label='Город'
          required
          fullWidth
          variant='outlined'
        />
      </div>

      <div className='section__wrapper__line'>
        <InputForm
          name='phoneNumber'
          key='phoneNumber'
          type='tel'
          label='Номер телефона'
          required
          fullWidth
          variant='outlined'
        />
        <InputForm
          name='email'
          key='email'
          type='email'
          label='Электронная почта'
          required
          fullWidth
          variant='outlined'
        />
      </div>
    </div>
  );
};

export default ContactInformation;
