import { useField } from "react-final-form";

import StyledInputForm from "../../styledMuiComponents/StyledInputForm";

const InputForm = props => {
  const { type } = props;

  const validate = value => {
    if (type === 'tel') {
      const isTel = /^((\+7)|8)\d{10}$/.test(value);

      return !isTel;
    }

    switch (type) {
      case 'tel':
        const isTel = /^((\+7)|8)\d{10}$/.test(value);
        return !isTel;
      
      case 'graduationYear':
        const isYear = /^\d{4}$/.test(value);

        return !isYear;

      case 'email':
        const isEmail = /.+@.+\..+/i.test(value);

        return !isEmail;

      case 'university':
        return !value;

      case 'learningProgram':
        const symbolsProgram = value?.split('');
        let validProgram = false;

        if (symbolsProgram) {
          validProgram = true;

          for (let symbol of symbolsProgram) {
            validProgram = validProgram && /[A-Za-zА-Яа-я\s\.]/u.test(symbol);
          }
        }
        
        return !validProgram;
    
      default:
        const symbols = value?.split('');
        let valid = false;

        if (symbols) {
          valid = true;

          for (let symbol of symbols) {
            valid = valid && /[A-Za-zА-Яа-я\s]/u.test(symbol);
          }
        }
        
        return !valid;
        // return !value;
    }
  };

  const { input, meta: { error, touched } } =
    useField(props.name, { validate: validate });

  const inputProps = {
    ...props,
    ...input
  };

  return (
    <StyledInputForm
      error={!!touched && !!error}
      {...inputProps}
    />
  );
};

export default InputForm;
