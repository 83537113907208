import { Field } from "react-final-form";

const CoverLetter = props => {
  return (
    <div className='section__wrapper'>
      <h2 className='section__wrapper__title'>
        {`Сопроводительное письмо`}
      </h2>

      <Field
        name='coverLetter'
      >
        {props => (
          <textarea
            className='text-area'
            onChange={event => props.input.onChange(event.target.value)}
          />
        )}
      </Field>

      <p className='text-area-hint'>{`Расскажи, чем тебя заинтересовала стажировка, какие навыки или достижения у тебя есть. Любая информация, которую сочтешь нужной.`}</p>
    </div>
  );
};

export default CoverLetter;
