import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

const PaperStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    maxWidth: '740px',
    // height: '70%',
    maxHeight: 'calc(100vh - 5%)',
    borderRadius: '6px',

    '@media(max-width: 500px)': {
      width: '80%',
      height: '65%'
    },

    '@media(max-width: 300px)': {
      width: '90%',
    }
  }
});

const StyledPaper = withStyles(PaperStyles)(Paper);

export default StyledPaper;
