import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

const inputStyles = theme => ({
  root: {
    margin: theme.spacing(1),

    '@media(max-width: 800px)': {
      margin: 0,
      marginBottom: '12px'
    },

    '@media(max-width: 500px)': {
      '& label': {
        fontSize: '14px'
      },

      '& input': {
        fontSize: '14px'
      }
    }
  }
});

const StyledInputForm = withStyles(inputStyles)(TextField);

export default StyledInputForm;
