import { useFormState } from "react-final-form";
import Checkbox from "@material-ui/core/Checkbox";

import cn from "classnames";

const CheckboxLine = props => {
  const {
    name,
    input,
    paragraphClassname,
    setIsVacancyPicked,
    wrapperClassname
  } = props;

  const { values } = useFormState();

  const onToggleCheckbox = (event, checked) => {
    input.onChange(event, checked);

    let selectedVacanciesLen = values?.vacancies?.length
      ? values?.vacancies?.length
      : 0;

    if (checked) {
      selectedVacanciesLen += 1;
    } else {
      selectedVacanciesLen -= 1;
    }

    setIsVacancyPicked(selectedVacanciesLen > 0);
  };

  return (
    <div className={cn(wrapperClassname
      ? wrapperClassname
      : 'checkbox-section'
    )}>
      <Checkbox
        color='primary'
        onChange={onToggleCheckbox}
      />
      <p
        className={cn(paragraphClassname
            ? paragraphClassname
            : 'section__info'
          )}
      >
        {name}
      </p>
    </div>
  );
};

export default CheckboxLine;
