import { useState } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import cn from 'classnames';

import Questionnaire from "./Questionnaire";
import SubmissionMessage from './components/SubmissionMessage';

function App() {
  const [submited, setSubmited] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#15425e'
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div className={cn(submited
          ? 'layout submission'
          : 'layout'
        )}>
        {!submited
          ? (
            <Questionnaire
              setSubmited={setSubmited}
            />
          )
          : <SubmissionMessage/>
        }

      </div>
    </MuiThemeProvider>
  );
}

export default App;
