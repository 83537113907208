import { useEffect, useMemo } from "react";
import { Field } from "react-final-form";

import CheckboxLine from "./CheckboxLine";

const CheckboxList = props => {
  const {
    data,
    name: fieldName,
    paragraphClassname,
    setIsVacancyPicked,
    wrapperClassname
  } = props;

  return data.map(({ id, name }) => (
    <Field
      name={fieldName}
      key={id}
      value={id}
      type='checkbox'
    >
      {props => (
        <CheckboxLine
          value={id}
          name={name}
          input={props.input}
          setIsVacancyPicked={setIsVacancyPicked}
          wrapperClassname={wrapperClassname}
          paragraphClassname={paragraphClassname}
        />
      )}
    </Field>
  ));
};

export default CheckboxList;
