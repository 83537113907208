import { useEffect, useRef, useState } from "react";
import cn from 'classnames';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";

import { sendFile, deleteResume } from "../requests/requests";

import { UPLOAD_RESUME_ERROR_TEXT } from "../assets/AppConst";

import StyledButton from "../styledMuiComponents/StyledButton";
import StyledCircularProgress from "../styledMuiComponents/StyledCircularProgress";

const Resume = props => {
  const {
    resumeId,
    setResumeId,
    resumeUploading,
    setResumeUploading
  } = props;

  const [originalFileName, setOriginalFileName] = useState('Файл не загружен');
  const [buttonName, setButtonName] = useState('Загрузить файл');
  const [isUploadError, setIsUploadError] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const inputRef = useRef(null);

  const FILE_SIZE = 5;

  const uploadFile = event => {
    const file = event.target.files[0];

    if (file?.size && file.size / 1024 ** 2 > FILE_SIZE) {
      setOriginalFileName(`Размер файла не должен превышать ${FILE_SIZE}Мб`);
      return;
    }

    if (file) {
      setOriginalFileName(file.name);
      setResumeUploading(true);
      setButtonName('Заменить файл');
      setFileUploaded(true);
      setIsUploadError(false);
  
      sendFile(file, {
        setResumeId,
        setResumeUploading,
        setIsUploadError
      });
    }
  };

  const deleteFile = event => {
    setOriginalFileName('Файл не загружен');
    setResumeId(null);
    setFileUploaded(false);
    setButtonName('Загрузить файл');

    deleteResume(resumeId);

    inputRef.current.value = '';
  };

  useEffect(() => {
    if (isUploadError) {
      setOriginalFileName(UPLOAD_RESUME_ERROR_TEXT);
      setFileUploaded(false);
      inputRef.current.value = '';
    }
  }, [isUploadError]);

  return (
    <div className='section__wrapper'>
      <h2 className='section__wrapper__title'>
        {`Резюме`}
      </h2>

      <div className='upload-button__container'>
        <StyledButton
          color='primary'
          variant="contained"
          component="label"
        >
          {buttonName}
          <input
            type="file"
            hidden
            accept="application/pdf, .pdf, .doc, .docx"
            onChange={uploadFile}
            ref={inputRef}
          />
        </StyledButton>

        <div className='file-status'>
          <p
            className={cn('upload-button-label', { 'upload-error-label': isUploadError })}
          >
            {originalFileName}
          </p>
          <div className='file-name__wrapper'>
          </div>
          <div className={cn({ 'loader__wrapper': resumeUploading || fileUploaded })}>
            {resumeUploading
              ? <StyledCircularProgress
                  style={{
                    width: '28px',
                    height: '28px'
                  }}
                />
              : (
                <>
                  {fileUploaded
                    ? (
                      <div className='icon-button__container'>
                        <IconButton
                          onClick={deleteFile}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )
                    : null
                  }
                </>
              )
            }
          </div>
        </div>
      </div>

      <p className='upload-button-hint'>
        Файл формата: PDF, DOC, DOCX. Размер: не более 5Мб.
      </p>

    </div>
  );
};

export default Resume;
