const SubmissionMessage = props => {
  return (
    <div className='container'>
      <div className='company-img__wrapper'>
        <img className='company' src={require('../assets/images/logo.png')} alt='Bellerage'/>
      </div>

      <div className='submission__wrapper'>
        <h2 className='submission__title'>Спасибо!</h2>
        <p className='submission__message'>
          Твоя кандидатура принята к рассмотрению. В случае одобрения мы свяжемся 
          с тобой в течение нескольких дней для приглашения на очный этап.
        </p>
      </div>
    </div>
  );
};

export default SubmissionMessage;
