import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const ButtonStyles = theme => ({
  root: {
    maxWidth: '164px',
    minWidth: '161px',
    
    '&.error-button': {
      maxWidth: '210px',
      minWidth: '200px'
    },

    '@media(max-width: 350px)': {
      marginBottom: '8px',
    }
  }
});

const StyledButton = withStyles(ButtonStyles)(Button);

export default StyledButton;
