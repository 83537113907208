// import { useContext, useEffect, useState } from "react";

// import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import { useField } from "react-final-form";

import StyledFormControl from "../../styledMuiComponents/StyledFormControl";
// import { useField, Field } from "react-final-form";

const SelectForm = props => {
  const {
    value,
    values,
    label,
    labelId,
    // className,
    multiple,
    // style
  } = props;

  const { input } = useField(props.name, { validate: validate });

  const handleChange = event => {
    input.onChange(event.target.value);
  };

  return (
    <StyledFormControl required variant="outlined" fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={handleChange}
      >
        {renderFunction({
          values,
          multiple,
          checkedValues: input.value
        })}
      </Select>
    </StyledFormControl>
  );
};

export default SelectForm;

function validate(value) {
  return !value;
}

function renderFunction(params) {
  const {
    multiple,
    values,
    checkedValues
  } = params;

  if (!multiple) {
    return (
      values.map(value => (
        <MenuItem
          value={value}
          key={value}
        >
          {value}
        </MenuItem>
      ))
    );
  }
  
  return (
    values.map(value => (
      <MenuItem key={value} value={value}>
        <Checkbox
          checked={checkedValues?.indexOf(value) > -1}
          color='primary'
        />
        <ListItemText primary={value}/>
      </MenuItem>
    ))
  );
}
