import FormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core/styles';

const formControlStyles = theme => ({
  root: {
    margin: theme.spacing(1),

    '@media(max-width: 800px)': {
      margin: 0,
      marginBottom: '12px'
    },

    '@media(max-width: 500px)': {
      '& label': {
        fontSize: '14px'
      },

      '& .MuiSelect-root': {
        fontSize: '14px'
      }
    }
  }
});

const StyledFormControl = withStyles(formControlStyles)(FormControl);

export default StyledFormControl;
