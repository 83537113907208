import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from '@material-ui/core/styles';

const CircularProgressStyles = theme => ({
  root: {
    width: '24px',
    height: '24px',
  }
});

const StyledCircularProgress = withStyles(CircularProgressStyles)(CircularProgress);

export default StyledCircularProgress;
