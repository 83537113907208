import { useEffect, useMemo, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import { createPersonalDataModalWindowContent } from "../common/commons";

import ModalWindow from "./ModalWindows/PersonalData";

const PersonalData = props => {
  const { setValid } = props;

  const [ policyChecked, setPolicyChecked ] = useState(false);
  const [ processingChecked, setProcessingChecked ] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalWindowContent = useMemo(() => createPersonalDataModalWindowContent(), []);

  useEffect(() => {
    setValid(policyChecked && processingChecked);
  }, [policyChecked, processingChecked]);
  
  return (
    <>
      <div className='section__personal-data'>
        <Checkbox
          color='primary'
          onChange={event => setPolicyChecked(event.target.checked)}
        />
        <p className='section__info'>
          {`Я ознакомлен(а) с`}&nbsp;
          <a
            href='https://www.bellerage.com/ru/legal/'
            target='_blank'
            className='privacy-policy-link'
          >
            {'Политикой конфиденциальности'}
          </a>
        </p>
      </div>

      <div className='section__personal-data'>
        <Checkbox
          color="primary"
          onChange={event => setProcessingChecked(event.target.checked)}
        />
        <p className='section__info'>
          {`Я согласен(-на) на`}&nbsp;
          <span
            className='section__info__processing'
            onClick={event => {
              setIsModalOpen(true);
            }}
          >
            {`обработку моих персональных данных`}
          </span>
        </p>
      </div>
      
      {isModalOpen
        ? (
          <ModalWindow
            title='Согласие на обработку персональных данных'
            content={modalWindowContent}
            onClose={(event) => {
              setIsModalOpen(false);
            }}
          />
        )
        : null
      }
    </>
  );
};

export default PersonalData;

// Я согласен(-на) на обработку моих персональных данных
