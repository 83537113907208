import { Form as FinalForm } from 'react-final-form';

const Form = props => {
  return (
    <FinalForm
      render={renderProps => (
        <form
          className='form'
          onSubmit={renderProps.handleSubmit}
        >
          {props.children(renderProps)}
        </form>
      )}
      {...props}
    />
  );
};

export default Form;
