export const createPersonalDataModalWindowContent = () => (
  <p>
    &nbsp;&nbsp;Я согласен с тем, что мои персональные данные, включая фамилию, имя, отчество, номер телефона, адрес электронной почты, сведения из резюме (далее «Пдн»), будут обрабатываться группой Bellerage: 
    ООО «Беллераж Аутсорсинг и Консалтинг» ОГРН 1117746102099, ООО «Беллераж Аудит» ОГРН 1097746796267, расположенные по адресу: 115054, г. Москва, ул. Щипок, д. 11, стр.1;
    ООО «Беллераж Менеджмент» ОГРН 1207700224247 и ООО «Алинга Рус» ОГРН 5147746190917, расположенные по адресу: 115054, г. Москва, ул. Щипок, д. 9/26, стр. 1, этаж 1, 
    далее каждая именуемая отдельно «Оператор»,
    в целях:<br/>
    &nbsp;&nbsp;-	прохождения стажировки,<br/>
    &nbsp;&nbsp;-	рассмотрения моей кандидатуры на замещение вакантной должности<br/>
    &nbsp;&nbsp;-	включения меня в резерв работников на прием <br/>
    путем совершения с использованием средств автоматизации или без использования таких средств действий (операций) или совокупности действий (операций) с ПДн, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение ПДн, передачу третьим лицам (предоставление, доступ).<br/><br/>

    &nbsp;&nbsp;Обработка и хранение ПДн осуществляются в пределах срока, необходимого для целей обработки ПДн, если отсутствуют законные основания для дальнейшей их обработки, например, если федеральным законом, договором с Субъектом или согласием Субъекта не установлен их срок хранения. Обрабатываемые ПДн подлежат уничтожению при наступлении одного из следующий условий:<br/>
    &nbsp;&nbsp;1.	достижение целей обработки ПДн или максимальных сроков хранения - в течение 30 календарных дней;<br/>
    &nbsp;&nbsp;2.	утрата необходимости в достижении целей обработки ПДн - в течение 30 календарных дней;<br/>
    &nbsp;&nbsp;3.	предоставление Субъектом или его законным представителем подтверждения того, что ПДн являются незаконно полученными или не являются необходимыми для заявленной цели обработки - в течение 10 календарных дней;<br/>
    &nbsp;&nbsp;4.	отзыв Субъектом согласия на обработку ПДн – в течение 30 календарных дней;<br/>
    &nbsp;&nbsp;5.	ликвидация (реорганизация) Оператора;<br/>
    &nbsp;&nbsp;6.	иные случаи, прямо предусмотренные законом.<br/>
    В случае отсутствия возможности уничтожения ПДн в течение указанного срока Оператор осуществляет блокирование таких ПДн или обеспечивает их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению Оператора) и обеспечивает уничтожение ПДн в срок не более чем 6 (шесть) месяцев, если иной срок не установлен федеральными законами.
  </p>
);

export const createErrorModalWindowContent = textContent => (
  <p>{textContent}</p>
);
