import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

import StyledPaper from "../../styledMuiComponents/StyledPaper";

const ModalWindow = props => {
  const {
    title,
    content,
    onClose
  } = props;

  return (
    <div
      className='modal-window-wrapper'
      onScroll={event => {
        event.stopPropagation();
      }}
      onClick={event => {
        event.stopPropagation();
        onClose(event);
      }}
    >
      <StyledPaper
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div
          className='modal-window-wrapper__header'
        >
          <p className='modal-window__title'>
            {title}
          </p>

          <IconButton
            aria-label='Закрыть'
            onClick={event => {
              onClose(event);
            }}
          >
            <ClearIcon />
          </IconButton>

        </div>

        <div
          className='modal-window__info'
        >
          {content}
        </div>
      </StyledPaper>
    </div>
  );
};

export default ModalWindow;
