import React from 'react';

import { getVacancies } from '../requests/requests';

import CheckboxList from './CheckboxList/CheckboxList';

const Vacancy = props => {
  const { setIsVacancyPicked } = props;

  const [vacancies, setVacancies] = React.useState([]);

  React.useEffect(() => {
    const q =  async () => {
      const respones = await getVacancies();

      if (respones) {
        setVacancies(respones);
      }
    };

    q();
  }, []);

  return (
    <div className='section__wrapper'>
      <h2 className='section__wrapper__title'>{`Вакансии`}</h2>

      <CheckboxList
        data={vacancies}
        name='vacancies'
        setIsVacancyPicked={setIsVacancyPicked}
      />

    </div>
  );
};

export default Vacancy;
