import { useState } from 'react';

import Button from '@material-ui/core/Button';

import { sendCandidateData } from './requests/requests';

import { SUBMITION_ERROR_TEXT } from './assets/AppConst';
import { InitialValues } from './assets/formInitialValues';

import './_styles.css';

import Vacancy from "./components/Vacancy";
import ContactInformation from "./components/ContactInformation";
import Education from './components/Education';
import CoverLetter from './components/CoverLetter';
import PersonalData from './components/PersonalData';
import Resume from './components/Resume';
import Form from './components/Forms/FinalForm';
import StyledCircularProgress from './styledMuiComponents/StyledCircularProgress';

const Questionnaire = props => {
  const { setSubmited } = props;

  const [isVacancyPicked, setIsVacancyPicked] = useState(false);
  const [validCheckboxes, setValidCheckboxes] = useState(false);

  const [resumeUploading, setResumeUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const [resumeId, setResumeId] = useState(null);

  return (
    <div className='container'>
      <div className='company-img__wrapper'>
        <img className='company' src={require('./assets/images/logo.png')} alt='Bellerage'/>
      </div>
      <Form
        initialValues={InitialValues}
        onSubmit={value => {
          setSubmitting(true);
          const date = new Date().getTime();

          const graduationYear = +value?.graduationYear;

          const data = {
            ...value,
            graduationYear,
            date,
            resumeId
          }
          sendCandidateData(data, {
            setSubmited,
            setSubmitting,
            setIsSubmitError
          });
        }}
      >
        {props => (
          <div className='form__wrapper'>
            <Vacancy
              setIsVacancyPicked={setIsVacancyPicked}
            />
            <ContactInformation/>
            <Education/>
            <Resume
              resumeId={resumeId}
              setResumeId={setResumeId}
              resumeUploading={resumeUploading}
              setResumeUploading={setResumeUploading}
            />
            <CoverLetter/>
            <PersonalData
              setValid={setValidCheckboxes}
            />

            <div className='footer'>
              {isSubmitError
                ? (
                  <p className='error-message'>
                    {SUBMITION_ERROR_TEXT}
                  </p>
                )
                : null
              }
              <div className='submit-button__container'>
                <div className='progress__wrapper'>
                  {submitting
                    ? (
                      <StyledCircularProgress
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                    />)
                    : null
                  }
                </div>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={props.invalid
                    || !isVacancyPicked
                    || !validCheckboxes
                    || resumeUploading
                    || submitting
                  }
                >
                  {`Подать заявку`}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>

    </div>
  );
};

export default Questionnaire;
